import { prop } from 'ramda'
import { BehaviorSubject, first, firstValueFrom, lastValueFrom, Observable, of } from 'rxjs'
import { filter, map, retry, switchMap, take, tap } from 'rxjs/operators'
import { nl } from 'date-fns/locale'
import { format, parse } from 'date-fns'
import { TranslateService } from '@ngx-translate/core'
import { MutationResult } from 'apollo-angular'
import { ApolloQueryResult } from '@apollo/client'
import { TranslateRouterService } from '@endeavour/ngx-translate-router'

import { Inject, Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Dialog } from '@angular/cdk/dialog'

import {
    AddToShoppingCartMutation,
    AddToShoppingCartMutationService,
    CreateDropshipmentOrderMutationService,
    CreateOrderMutationService,
    DefaultResponseFragment,
    DeleteFromShoppingCartMutationService,
    IsOrderReferenceAvailableQueryService,
    OrderLineInput,
    ResponseStatuses,
    ShoppingCartFragment,
    ShoppingCartLineFragment,
    ShoppingCartLineInput,
    ShoppingCartProductFragment,
    ShoppingCartQuery,
    ShoppingCartQueryService,
    ShoppingCartTotalItemsQueryService,
    UpdateShoppingCartMutationService,
    UserFragment,
} from '@app-graphql/schema'
import { getIndexName } from '@app-lib/algolia.lib'
import { ALGOLIA_INSIGHTS_CLIENT, InsightsData } from '@app-domains/algolia/algolia.module'
import { LocalizationService } from '@app-domains/localization/service/localization.service'
import { AddedToCartDialogComponent } from '@app-domains/checkout/components/added-to-cart-dialog/added-to-cart-dialog.component'
import { OutOfStockModalComponent } from '../../pages/shopping-cart/components/out-of-stock-modal/out-of-stock-modal.component'
import { OrderHeaderStep, ShipmentSegment } from '@app-domains/checkout/types/checkout.types'
import { AssortmentService, AuthService, DebtorService, SearchService } from '@app-services'
import { AnalyticsService } from '@app-domains/analytics/analytics.service'
import { Product } from '@app-domains/content-blocks/components/product-card/product-card.types'
import { CountriesEnum } from '@app-types/common.types'
import { Analytics } from '@app-domains/analytics/types/analytics.types'
import { cannotBeAddedToCart } from '../../helpers/cannot-be-added-to-cart/cannot-be-added-to-cart'
import { DialogComponent, DialogData } from '@app-domains/ui/components/dialog/dialog.component'
import { InputPublic } from '@app-domains/ui/components/input/input.component.types'

const DEFAULT_SHOPPING_CART = 'default'

@Injectable({
    providedIn: 'root',
})
export class CheckoutService {

    constructor(
        private authService: AuthService,
        private debtorService: DebtorService,
        private assortmentService: AssortmentService,
        private localization: LocalizationService,
        private analyticsService: AnalyticsService,
        private recentSearches: SearchService,
        private translate: TranslateService,
        private translateRouterService: TranslateRouterService,
        private shoppingCartQueryService: ShoppingCartQueryService,
        private shoppingCartTotalItemsQueryService: ShoppingCartTotalItemsQueryService,
        private isOrderReferenceAvailableQueryService: IsOrderReferenceAvailableQueryService,
        private deleteFromShoppingCartMutationService: DeleteFromShoppingCartMutationService,
        private updateShoppingCartMutationService: UpdateShoppingCartMutationService,
        private addToShoppingCartMutationService: AddToShoppingCartMutationService,
        private readonly createDropshipmentOrderMutationService: CreateDropshipmentOrderMutationService,
        private createOrderMutationService: CreateOrderMutationService,
        private router: Router,
        private dialog: Dialog,
        @Inject(ALGOLIA_INSIGHTS_CLIENT) protected insights: InsightsData,
    ) {
    }

    public addedToShoppingCart$ = new BehaviorSubject<ShoppingCartLineInput[]>([])
    public outOfStockProductsPopup$ = new BehaviorSubject<Product[]>([])

    public orderStatus$ = new BehaviorSubject<ResponseStatuses | null>(null)

    public isLoading$ = new BehaviorSubject(false)
    public placingOrder: boolean = false
    public orderHeaderSteps = new BehaviorSubject<OrderHeaderStep[]>([
        {
            name: 'cart',
            isDone: true,
            isActive: false,
            link: '/',
        },
        {
            name: 'delivery',
            isDone: false,
            isActive: false,
            link: '/',
        },
        {
            name: 'review',
            isDone: false,
            isActive: false,
            link: '/',
        },
    ])

    public orderMode$ = new BehaviorSubject<'normal' | 'dropshipment'>('normal')

    public selectedItemsInShoppingCart$ = new BehaviorSubject<ShoppingCartLineFragment[]>([])

    public itemsMinimalQuantityNotReached$ = new BehaviorSubject<ShoppingCartLineFragment[]>([])

    public shoppingCart$ = new BehaviorSubject<ShoppingCartFragment>({
        cartLine: [],
        totalItems: 0,
        total: 0,
    })

    public shipments$ = new BehaviorSubject<Array<ShipmentSegment[]>>([])
    public hasOutOfStockItems = false
    public deliveryFormGroup = new FormGroup({
        name: new FormControl('', [
            Validators.required,
        ]),
        postalCode: new FormControl('', [
            Validators.required,
        ]),
        houseNumber: new FormControl('', [
            Validators.required,
        ]),
        streetName: new FormControl('', [
            Validators.required,
        ]),
        city: new FormControl('', [
            Validators.required,
        ]),
        country: new FormControl<CountriesEnum>(CountriesEnum.nl, {
            nonNullable: true,
            validators: [
                Validators.required,
            ],
        }),
        phoneNumber: new FormControl('', [
            Validators.required,
            Validators.pattern('[- +()0-9]+'),
        ]),
        email: new FormControl('', [
            Validators.required,
            Validators.email,
        ]),
    })

    public referenceFormGroup = new FormGroup({
        reference: new FormControl('', [
            Validators.required,
            Validators.maxLength(20),
        ]),
        description: new FormControl('', [
            Validators.maxLength(30),
        ]),
        addToAssortment: new FormControl(true),
    })

    public referenceErrors: InputPublic.ErrorMessageRecord = {
        referenceNotAvailable: this.translate.instant('validation.messages.reference-not-available'),
        required: this.translate.instant('validation.messages.required'),
        maxlength: this.translate.instant('validation.messages.max-length', {
            requiredLength: 20,
        }),
    }

    public descriptionErrors: InputPublic.ErrorMessageRecord = {
        maxlength: this.translate.instant('validation.messages.max-length', {
            requiredLength: 30,
        }),
    }

    public overruleShoppingCart(): void {
        const selectedItems = this.selectedItemsInShoppingCart$.getValue()

        const totalPrice = selectedItems.reduce((acc, item) => {
            return acc + Number(item.subTotal)
        }, 0)

        this.shoppingCart$.next({
            cartLine: selectedItems,
            totalItems: selectedItems.length,
            total: totalPrice,
        })
    }

    public setOrderStatus(status: ResponseStatuses): void {
        this.orderStatus$.next(status)
    }

    public deleteFromShoppingCart(ean: [string], cartName: string = DEFAULT_SHOPPING_CART) {
        const shoppingCart: ShoppingCartLineFragment[] = this.shoppingCart$.getValue().cartLine

        const deletedProducts: ShoppingCartLineFragment[] = shoppingCart
            .filter((scl) => ean.includes(scl.product!.ean))

        const locale = this.localization.getCurrentLocale()

        this.analyticsService.logCart(locale, deletedProducts, 'remove')

        return of(undefined).pipe(
            switchMap(() => this.deleteFromShoppingCartMutationService.mutate({
                name: cartName,
                eans: ean as [string],
            })),
            tap((r) => this.shoppingCart$.next(r.data!.deleteFromShoppingCart)),
        )
    }

    public emptyShoppingCart(updateShoppingCart: boolean = false, cartName: string = DEFAULT_SHOPPING_CART) {
        const shoppingCartLines = this.shoppingCart$.getValue().cartLine

        const locale = this.localization.getCurrentLocale()

        this.analyticsService.logCart(locale, shoppingCartLines, 'remove')

        return firstValueFrom(this.shoppingCart$.pipe(
            take(1),
            map((shoppingCart) => shoppingCart.cartLine.map((cartLine) => cartLine.product!.ean)),
            switchMap((eans) => this.deleteFromShoppingCartMutationService.mutate({
                name: cartName,
                eans: eans as string[],
            }).pipe(
                retry(3),
            )),
            tap((response) => {
                // In some cases we don't want to update the local shopping-cart state as it is being used by other
                // components that still need to show it.
                // But the totalItems should always be updated for the header badge
                if (updateShoppingCart) {
                    this.shoppingCart$.next(response.data!.deleteFromShoppingCart)
                } else {
                    this.shoppingCart$.next({
                        ...this.shoppingCart$.getValue(),
                        totalItems: response.data!.deleteFromShoppingCart.totalItems,
                    })
                }
            }),
        ))
    }

    public async addToShoppingCart(
        shoppingCartLines: ShoppingCartLineInput[],
        cartName = DEFAULT_SHOPPING_CART,
    ): Promise<MutationResult<AddToShoppingCartMutation>> {
        return lastValueFrom(of(undefined).pipe(
            tap(() => this.isLoading$.next(true)),
            switchMap(() => this.addToShoppingCartMutationService.mutate(
                {
                    input: {
                        name: cartName,
                        shoppingCartLine: shoppingCartLines,
                    },
                },
            )),
            tap(() => {
                const index = getIndexName({
                    locale: this.localization.getCurrentLocale(),
                    sorting: null,
                })

                this.insights.client('convertedObjectIDs', {
                    index: index,
                    objectIDs: shoppingCartLines.map((l) => l.ean),
                    eventName: 'addToCart',
                    userToken: this.insights.token.getValue() ?? '',
                })

                const recent = this.recentSearches.getRecentSearches()
                const recentEANs = recent.map(prop('ean'))
                const recentlySearched = shoppingCartLines
                    .map(prop('ean'))
                    .filter((ean) => recentEANs.includes(ean))

                recentlySearched.forEach((r) => {
                    const query = recent.find((rec) => rec.ean === r)!.queryID
                    this.insights.client('convertedObjectIDsAfterSearch', {
                        index: index,
                        objectIDs: [r],
                        queryID: query,
                        eventName: 'addToCart',
                        userToken: this.insights.token.getValue() ?? '',
                    })
                })
            }),
            tap((r) => {
                const addedProducts = shoppingCartLines.map(prop('ean'))
                const newProducts: ShoppingCartLineFragment[] = r.data!.addToShoppingCart!.cartLine
                    .filter((p) => addedProducts.includes(p.product!.ean))

                const locale = this.localization.getCurrentLocale()

                this.analyticsService.logCart(locale, newProducts, 'add')
            }),
            tap((r) => this.shoppingCart$.next(r.data!.addToShoppingCart)),
            tap(() => this.isLoading$.next(false)),
            tap(() => {
                this.addedToShoppingCart$.next(shoppingCartLines)

                this.openAddedToShoppingCartSidebar()
            }),
        ))
    }

    public getShoppingCartTotalItems(cartName: string = DEFAULT_SHOPPING_CART): Promise<number> {
        return firstValueFrom(this.authService.user$.pipe(
            filter((user): user is UserFragment => !! (user && user.debtorCode)),
            switchMap(() => this.shoppingCartTotalItemsQueryService.fetch({ name: cartName })),
            map((r) => r.data.shoppingCart.totalItems),
            tap((r) => {
                let currentShoppingCart = this.shoppingCart$.getValue()

                let newShoppingCart = {
                    ...currentShoppingCart,
                    totalItems: r,
                }

                this.shoppingCart$.next(newShoppingCart)
            }),
        ))
    }

    public getShoppingCart(cartName: string = DEFAULT_SHOPPING_CART): Observable<ApolloQueryResult<ShoppingCartQuery>> {
        return of(undefined).pipe(
            tap(() => this.isLoading$.next(true)),
            tap(() => this.selectedItemsInShoppingCart$.next([])),
            switchMap(() => this.shoppingCartQueryService.fetch({ name: cartName }, { fetchPolicy: 'no-cache' })),
            tap((r) => this.shoppingCart$.next(r.data?.shoppingCart)),
            tap(() => this.isLoading$.next(false)),
        )
    }

    public updateShoppingCart(
        shoppingCartLines: ShoppingCartLineInput[],
        cartName: string = DEFAULT_SHOPPING_CART,
    ) {
        return of(undefined).pipe(
            switchMap(() => this.updateShoppingCartMutationService.mutate({
                input: {
                    name: cartName,
                    shoppingCartLine: shoppingCartLines,
                },
            })),
            tap((r) => this.shoppingCart$.next(r.data!.updateShoppingCart)),
        )
    }

    public createDropshipmentOrderLines(): OrderLineInput[] {
        const shoppingCart = this.shoppingCart$.getValue()

        const orderLines: OrderLineInput[] = []

        shoppingCart.cartLine.forEach((line) => {
            orderLines.push(
                {
                    ean: String(line.product?.ean),
                    quantity: Number(line.quantity),
                    delivery: String(line.product?.orderProperties?.deliveryInfo.weekYear),
                },
            )
        })

        return orderLines
    }

    public createShipmentsOrderLines(): OrderLineInput[] {
        const shipments = this.shipments$.getValue()

        const orderLines: OrderLineInput[] = []

        shipments.forEach((shipment: ShipmentSegment[]) => {
            // Find the highest year in the shipment
            const highestYear = Math.max(...shipment.map(segment => segment.year))

            // Filter segments for the highest year
            const shipmentsByHighestYear = shipment.filter(line => line.year === highestYear)

            // Determine the highest week in the highest year
            const highestWeek = shipmentsByHighestYear.reduce((acc, value) => {
                return acc < value.week ? value.week : acc
            }, 0)

            shipment.forEach(segment => {
                segment.products.forEach(product => {
                    const quantity = segment.orderInfo.find(line => line.product.ean === product.ean)?.amount || 0

                    orderLines.push({
                        ean: String(product?.ean),
                        quantity: Number(quantity),
                        delivery: `${highestWeek}-${highestYear}`,
                    })
                })
            })
        })

        return orderLines
    }

    public createShipmentsFromShoppingCart(): void {
        const shoppingCart = this.shoppingCart$.getValue()

        const shipmentsNotSorted: ShipmentSegment[] = []

        // Process each line to extract shipment details
        shoppingCart.cartLine.forEach(line => {
            const deliveryInfo = line.product?.orderProperties?.deliveryInfo
            const weekYear = deliveryInfo?.weekYear || ''
            const [week, year] = weekYear.split('-').map(Number)

            if (! week && year) {
                console.error('Invalid delivery info for line', line)
                return
            }

            shipmentsNotSorted.push({
                products: [line.product as ShoppingCartProductFragment],
                amount: Number(line.quantity),
                subTotal: Number(line.subTotal),
                week,
                year,
                orderInfo: [],
            })
        })

        const shipmentsMap = new Map<string, ShipmentSegment>()

        // Merge shipments by year and week
        shipmentsNotSorted.forEach(shipment => {
            const key = `${shipment.year}-${shipment.week}`

            if (shipmentsMap.has(key)) {
                const existingShipment = shipmentsMap.get(key)!
                existingShipment.orderInfo.push({
                    product: shipment.products[0],
                    amount: shipment.amount,
                })
                existingShipment.amount += shipment.amount
                existingShipment.products.push(...shipment.products)
                existingShipment.subTotal += shipment.subTotal
            } else {
                const newShipment = { ...shipment }
                newShipment.orderInfo.push({
                    product: shipment.products[0],
                    amount: shipment.amount,
                })
                shipmentsMap.set(key, newShipment)
            }
        })

        const shipments = Array.from(shipmentsMap.values())

        shipments.sort((shipment1, shipment2) => {
            if (shipment1.year === shipment2.year) {
                return shipment1.week - shipment2.week
            }

            return shipment1.year - shipment2.year
        })

        this.shipments$.next([shipments])
    }

    public async placeDropshipmentOrder(): Promise<DefaultResponseFragment | undefined> {
        const deliveryForm = this.deliveryFormGroup.value

        const referenceForm = this.referenceFormGroup.value

        const orderLines = this.createDropshipmentOrderLines()

        return await firstValueFrom(
            of(undefined).pipe(
                tap(() => this.isLoading$.next(true)),
                switchMap(() => this.createDropshipmentOrderMutationService.mutate({
                    input: {
                        email: deliveryForm.email,
                        address: {
                            name: deliveryForm.name as string,
                            city: deliveryForm.city as string,
                            number: deliveryForm.houseNumber as string,
                            country: deliveryForm.country?.toUpperCase() as string,
                            phone: deliveryForm.phoneNumber as string,
                            street: deliveryForm.streetName as string,
                            zipcode: deliveryForm.postalCode as string,
                        },
                        description: referenceForm.description,
                        orderLines: orderLines,
                        reference: referenceForm.reference,
                        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    },
                })),
                map((r) => r.data?.CreateDropshipmentOrder),
                tap((r) => this.setOrderStatus(r?.status || ResponseStatuses.Failed)),
                tap((r) => r?.status === 'SUCCESS' ? this.emptyShoppingCart() : ''),
                tap((r) => r?.status === 'SUCCESS' ? this.resetOrderStep() : ''),
                tap(() => this.isLoading$.next(false)),
            ),
        )
    }

    public async placeNormalOrder() {
        await this.authService.refreshUser()

        const referenceForm = this.referenceFormGroup.value

        const orderLines = this.createShipmentsOrderLines()

        this.isLoading$.next(true)

        const placedOrder = await firstValueFrom(
            this.authService.user$
                .pipe(
                    switchMap((user) => {
                        return this.createOrderMutationService.mutate({
                            input: {
                                email: user!.email,
                                orderLines: orderLines,
                                reference: referenceForm.reference,
                                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                            },
                        })
                    }),
                    map((r) => {
                        if (r.errors) {
                            this.setOrderStatus(ResponseStatuses.Failed)

                            let errorMessage = ''

                            r.errors?.forEach((x) => {
                                errorMessage = x.message
                            })

                            const dialogRef = this.dialog.open(DialogComponent, {
                                id: 'server-error',
                                data: {
                                    title: this.translate.instant(
                                        'order-status.sorry, something-went-wrong-with-your-order',
                                    ),
                                    message: this.translate.instant(errorMessage),
                                } as DialogData,
                            })

                            dialogRef.closed.pipe(take(1)).subscribe(() => {
                                this.setOrderHeaderStepDoneState(['delivery'], false)

                                this.router.navigate([this.translateRouterService.translateRouteLink('/shopping-cart')])
                            })

                            return this.isLoading$.next(false)
                        }

                        return r.data?.createOrder
                    }),
                    tap((r) => this.setOrderStatus(r?.status || ResponseStatuses.Failed)),
                ),
        )

        if (referenceForm.addToAssortment) {
            const eans: string[] = orderLines.reduce((acc: string[], line) => {
                if (line.ean) {
                    acc.push(line.ean)
                }

                return acc
            }, [])

            await firstValueFrom(
                this.assortmentService.addToAssortment(eans as [string])
                    .pipe(retry(3)))
        }

        if (placedOrder?.status === 'SUCCESS') {
            this.resetOrderStep()
            this.isLoading$.next(false)
            return this.emptyShoppingCart()
        }

        return this.isLoading$.next(false)
    }

    public setOrderHeaderStepActiveState(stepName: 'delivery' | 'cart' | 'review', isActive: boolean): void {
        this.orderHeaderSteps.next(
            this.orderHeaderSteps.getValue().map((step) => {
                step.isActive = step.name === stepName ? isActive : false
                return step
            }),
        )
    }

    public setOrderHeaderStepDoneState(stepNames: string[], isDone: boolean): void {
        const orderSteps = this.orderHeaderSteps.getValue()
        this.orderHeaderSteps.next(
            orderSteps.map((step) => {
                if (stepNames.includes(step.name)) {
                    step.isDone = isDone
                }

                return step
            }),
        )
    }

    public resetOrderStep(): void {
        this.orderHeaderSteps.next([
            {
                name: 'cart',
                isDone: true,
                isActive: false,
                link: '/',
            },
            {
                name: 'delivery',
                isDone: false,
                isActive: false,
                link: '/',
            },
            {
                name: 'review',
                isDone: false,
                isActive: false,
                link: '/',
            },
        ])
    }

    public checkMinimalOrderQuantity(orderType: 'normal' | 'dropshipment' = 'normal'): void {
        const selectedItems = this.selectedItemsInShoppingCart$.getValue() as ShoppingCartLineFragment[]

        let cartLines: ShoppingCartLineFragment[] = this.shoppingCart$.getValue().cartLine as ShoppingCartLineFragment[]

        if (selectedItems.length > 0) {
            cartLines = selectedItems
        }

        const invalidQuantityOrderLines = cartLines.filter((line: ShoppingCartLineFragment) => {
            if (orderType === 'normal') {
                return (Number(line.quantity) % Number(line.product?.orderProperties?.productOrderSteps) !== 0)
            } else if (orderType === 'dropshipment') {
                return (Number(line.quantity) %
                    Number(line.product?.orderProperties?.productOrderStepDropshipment) !== 0)
            }
            return false
        })

        this.itemsMinimalQuantityNotReached$.next(invalidQuantityOrderLines)
    }

    public openAddedToShoppingCartSidebar(): void {
        const dialogRef = this.dialog.open(AddedToCartDialogComponent, {
            height: '400px',
            width: '600px',
            panelClass: 'add-to-shopping-cart-dialog',
        })

        dialogRef.closed.pipe(take(1)).subscribe(() => {
            this.addedToShoppingCart$.next([])
            this.outOfStockProductsPopup$.next([])
        })
    }

    public getLastDropshipmentDate(): string {
        let cartLines = this.shoppingCart$.getValue().cartLine

        if (this.selectedItemsInShoppingCart$.getValue().length > 0) {
            cartLines = this.selectedItemsInShoppingCart$.getValue()
        }

        cartLines = cartLines.sort((b, a) =>
            Number(a.product?.orderProperties?.deliveryInfo.weekNumber) -
            Number(b.product?.orderProperties?.deliveryInfo.weekNumber),
        )

        if (cartLines.length > 0) {
            const weekNumber = String(cartLines[0].product?.orderProperties?.deliveryInfo.weekNumber)

            if (! weekNumber || weekNumber === '0') {
                return ''
            }

            return format(parse(weekNumber,
                'I', new Date()), 'dd/MM/yyyy', {
                locale: nl,
            })
        }

        return ''
    }

    public async checkForNotAvailableProducts(): Promise<void> {
        const cartLines = this.shoppingCart$.getValue().cartLine

        const outOfStockProducts: ShoppingCartLineFragment[] =
            cartLines.filter((x) => cannotBeAddedToCart(x.product!.price?.standardPrice!, x.product!.status))

        if (outOfStockProducts.length) {
            this.hasOutOfStockItems = true

            const dialog = this.dialog.open(OutOfStockModalComponent, {
                data: outOfStockProducts,
            })

            const closedResult = await firstValueFrom(dialog.closed)

            if (closedResult === 'delete-out-of-stock') {
                const eanCodes = outOfStockProducts.map((x) => x.product!.ean)

                if (eanCodes.length) {
                    await firstValueFrom(this.deleteFromShoppingCart(eanCodes as [string]))
                    this.getShoppingCart()
                    this.hasOutOfStockItems = false
                }
            }
        }
    }

    public createAnalyticsCheckoutStep(
        currentStep: number,
        event: 'begin_checkout' | 'add_shipping_info' | 'add_payment_info' | 'purchase',
        shippingTier: 'delivery' | 'dropshipment',
    ): void {
        // TODO: add item_variant

        const locale = this.localization.getCurrentLocale()

        const cartLines = this.shoppingCart$.getValue().cartLine

        const pItems: Analytics.PItem[] = cartLines.map((cartLine) => {
            const item: Analytics.PItem = {
                item_id: cartLine.product!.ean,
                item_name: cartLine.product!.translations[locale].name,
                item_brand: cartLine.product!.brand?.name ?? '',
                item_category: cartLine.product?.parentCategory?.translations[locale].name,
                price: cartLine.price,
                quantity: cartLine.quantity,
                currency: 'EUR',
            }

            cartLine.product?.categories.forEach((cat, index) => {
                item[index ? 'item_category' + (index + 1) : 'item_category'] = cat.translations[locale].name
            })

            return item
        })

        const total = cartLines.reduce((acc: number, item: ShoppingCartLineFragment) => {
            return acc + Number(item!.price) * Number(item!.quantity)
        }, 0)

        this.debtorService.debtor$.pipe(first()).subscribe((debtor) => {
            if (event === 'purchase') {
                const isFreeShipping = total >= (+debtor!.paymentCondition.francoLimit * 100)
                const orderFee = 100 * Number(isFreeShipping ? 0 : debtor!.paymentCondition.orderSurcharge)
                this.analyticsService.logCheckoutStep(
                    pItems,
                    currentStep,
                    event,
                    shippingTier,
                    total + orderFee,
                    orderFee,
                )
            } else {
                this.analyticsService.logCheckoutStep(pItems, currentStep, event, shippingTier)
            }
        })
    }

    public checkReferenceForm(): void {
        Object.values(this.referenceFormGroup.controls).forEach((c) => c.markAsDirty())

        this.referenceFormGroup.markAllAsTouched()
    }

    public handleReferenceChange(control: FormControl, value: string): void {
        this.checkReferenceAvailability(value)
            .then((available) => {
                if (! available) {
                    return control.setErrors({ referenceNotAvailable: true })
                }
            })
    }

    private async checkReferenceAvailability(reference: string): Promise<boolean> {
        return await firstValueFrom(this.isOrderReferenceAvailableQueryService
            .fetch({ input: reference })
            .pipe(
                map(({ data }) => data?.orderCheckIfReferenceIsAvailable),
            ))
    }
}
